"use client";

/**
 * Third-party libraries.
 */
import { Button, Divider, Layout } from "antd";
import { usePathname, useRouter } from "next/navigation";
import { ReactNode, useEffect, useMemo } from "react";

/**
 * Project components.
 */
import { AgentList, AgentListProps } from "@/components/client/agent";
import { useAuthenticationContext } from "@/components/client/authentication";
import { useApplicationContext } from "@/components/client/context";
import {
  UserAvailabilityStatus,
  useUserEventSubscription,
  useUsersQuery,
} from "@/components/client/graphql";
import { ContactsOutlined, ContainerOutlined } from "@ant-design/icons";

/**
 * Navigation menu item.
 */
type NavigationMenuItem = {
  /**
   * The icon to display.
   */
  icon: ReactNode;
  /**
   * The label to display.
   */
  label: string;
  /**
   * The path to navigate to.
   */
  path: string;
};

/**
 * Menu items that show in the sidebar.
 * Used to navigate to different pages.
 */
const navigationMenu: NavigationMenuItem[] = [
  {
    icon: <ContainerOutlined />,
    label: "Team Inbox",
    path: "/",
  },
  {
    icon: <ContactsOutlined />,
    label: "Phone Book",
    path: "/phone-book",
  },
  // {
  //   icon: "chart",
  //   label: "Analytics",
  //   path: "/analytics",
  // },
];

/**
 *
 * Global layout for all pages, include sidebar and content areas. Children will displayed in content area.
 * Requires authentication to access this component.
 */
export function DefaultProtectedPageLayoutSidebar() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const router = useRouter();
  const pathName = usePathname();

  const { mockData, setUserAvailabilityStatus } = useApplicationContext();
  const { user } = useAuthenticationContext();

  const {
    data: usersResponse,
    error: usersError,
    loading: usersLoading,
    refetch: refetchUsers,
  } = useUsersQuery();

  const {} = useUserEventSubscription({
    onData: (data) => {
      refetchUsers();
    },
  });

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const agentList = useMemo<AgentListProps["agents"]>(() => {
    if (!usersResponse || usersLoading) {
      return [];
    }

    if (mockData) {
      return [
        {
          name: "John Doe",
          status: UserAvailabilityStatus.Available,
          statusDateTime: new Date(),
        },
        {
          name: "Jane Smith",
          status: UserAvailabilityStatus.Break,
          statusDateTime: new Date(),
        },
        {
          name: "Michael Johnson",
          status: UserAvailabilityStatus.OnACall,
          statusDateTime: new Date(),
        },
        {
          name: "Emily Brown",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "David Wilson",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Sarah Johnson",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Robert Davis",
          status: UserAvailabilityStatus.Break,
          statusDateTime: new Date(),
        },
        {
          name: "Olivia Wilson",
          status: UserAvailabilityStatus.OnACall,
          statusDateTime: new Date(),
        },
        {
          name: "James Brown",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Sophia Smith",
          status: UserAvailabilityStatus.Available,
          statusDateTime: new Date(),
        },
        {
          name: "William Johnson",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Emma Davis",
          status: UserAvailabilityStatus.Break,
          statusDateTime: new Date(),
        },
        {
          name: "Alexander Wilson",
          status: UserAvailabilityStatus.OnACall,
          statusDateTime: new Date(),
        },
        {
          name: "Mia Brown",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Ethan Smith",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Ava Johnson",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
      ];
    }

    return (usersResponse.users ?? []).map((user) => ({
      name: user.profile.fullName,
      status: user.availability.status,
      statusDateTime: new Date(user.availability.updatedDate),
    }));
  }, [mockData, usersLoading, usersResponse]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the user availability status on the header when the user status changes.
   */
  useEffect(() => {
    const matchingUser = usersResponse?.users.find(
      (_user) => _user.email === user?.email
    );

    if (!matchingUser) {
      return;
    }

    setUserAvailabilityStatus({
      local: true,
      status: matchingUser?.availability.status,
    });
  }, [setUserAvailabilityStatus, user?.email, usersResponse?.users]);

  return (
    <Layout.Sider
      className="!bg-white h-full flex flex-col !gap-3 !py-5 !px-4"
      width={220}
      style={{
        borderRight: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      <div className="h-full w-full flex flex-col gap-3">
        <div className="flex flex-col gap-3 w-full leading-7">
          {navigationMenu.map((navigationMenuItem) => (
            <Button
              icon={navigationMenuItem.icon}
              id={navigationMenuItem.label.replace(" ", "")}
              key={navigationMenuItem.path}
              onClick={() => router.push(navigationMenuItem.path)}
              size="large"
              style={{
                fontSize: "0.875rem",
                justifyContent: "flex-start",
              }}
              type={pathName === navigationMenuItem.path ? "primary" : "text"}
            >
              {navigationMenuItem.label}
            </Button>
          ))}
        </div>
        <Divider className="!m-0" />
        <div className="w-full overflow-y-auto">
          <AgentList
            agents={agentList}
            loading={!agentList?.length && usersLoading}
          />
        </div>
      </div>
    </Layout.Sider>
  );
}
