"use client";

/**
 * Third-party libraries.
 */
import Icon, {
    CloseOutlined as CloseOutlinedIcon,
    SettingOutlined as SettingOutlinedIcon,
} from "@ant-design/icons";
import {
    Button,
    Drawer,
    Dropdown,
    Layout,
    MenuProps,
    Select,
    Tag,
    Tooltip,
} from "antd";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { UserAvatar } from "@/components/client/avatar";
import { useApplicationContext } from "@/components/client/context";
import { Dialer } from "@/components/client/dialer";
import {
    useCallCreateMutation,
    UserAvailabilityStatus,
    useSystemPreferenceQuery,
} from "@/components/client/graphql";
import { ThePiqueLabLogo } from "@/components/client/images";
import DialPad from "@/components/client/images/dial-pad.svg";
import { Loading } from "@/components/client/loading";
import { useTwilioContext } from "@/components/client/twilio";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { ApiRoute } from "@/components/common/route";
import { StringUtility } from "@/components/common/string/utilities";
import { SystemPreferenceKey } from "@/components/common/system-preference";

/**
 * Default protected page layout header properties.
 */
type DefaultProtectedPageLayoutHeaderProps = {
  // /**
  //  * Callback when the user availability status changes.
  //  */
  // onUserAvailabilityStatusChange?: (args: {
  //   /**
  //    * User availability status.
  //    */
  //   status: UserAvailabilityStatus;
  // }) => void;
  // /**
  //  * User availability status.
  //  */
  // userAvailabilityStatus?: UserAvailabilityStatus;
};

const HEADER_ICON_CLASSES = "!text-2xl !text-tpl-navy";

/**
 *
 * Global layout for all pages, include sidebar and content areas. Children will displayed in content area.
 * Requires authentication to access this component.
 */
export function DefaultProtectedPageLayoutHeader({}: DefaultProtectedPageLayoutHeaderProps) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================
  const router = useRouter();
  /**
   * Logged on user details.
   */
  const { user, error, fetching } = useAuthenticationContext();

  /**
   * Application Context.
   */
  const {
    setShowDialer,
    setShowSettings,
    setUserAvailabilityStatus,
    showDialer,
    showSettings,
    updatingUserAvailabilityStatus,
    userAvailabilityStatus,
  } = useApplicationContext();

  const {
    data: businessHoursSettingsData,
    loading: loadingBusinessHoursSettings,
  } = useSystemPreferenceQuery({
    variables: {
      filter: {
        key: SystemPreferenceKey.BUSINESS_STATUS,
      },
    },
  });

  const [createCall, { loading: creatingCall }] = useCallCreateMutation();

  /**
   * Twilio Context.
   */
  const { device, deviceRegistering } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * User initials.
   */
  const [userInitials, setUserAvatarInitials] = useState<string | null>();

  /**
   * User avatar menu items.
   */
  const [userAvatarMenuItems, setUserAvatarMenuItems] =
    useState<MenuProps["items"]>();

  /**
   * Indicates that the business is open.
   */
  const isBusinessOpen = useMemo(
    () =>
      businessHoursSettingsData?.systemPreference?.value === undefined ||
      businessHoursSettingsData?.systemPreference?.value === "open",
    [businessHoursSettingsData?.systemPreference?.value]
  );

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  const onDialerCall = useCallback(
    async ({
      phoneNumber,
    }: {
      /**
       * Phone number to call.
       */
      phoneNumber: string;
    }) => {
      await createCall({
        variables: {
          input: {
            to: phoneNumber,
          },
        },
      }).then((data) => {
        // Hide the dialer.
        setShowDialer(false);
      });
    },
    [createCall, setShowDialer]
  );

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Set the user avatar initials and menu items.
   */
  useLayoutEffect(() => {
    // Get the user initials.
    setUserAvatarInitials(
      StringUtility.getInitials({ input: user?.name ?? "", maxLength: 2 })
    );

    /**
     * Create the drop down menu for the user avatar.
     */
    setUserAvatarMenuItems([
      {
        key: "1",
        disabled: true,
        label: (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.antgroup.com"
          >
            {user?.name}
          </Link>
        ),
      },
      {
        key: "2",
        label: (
          <Link href={ApiRoute.AUTHENTICATION_LOGOUT} prefetch={false}>
            Logout
          </Link>
        ),
      },
    ]);
  }, [router, user]);

  if (fetching) {
    return <Loading size="large" />;
  }

  if (error) return <div>{error.message}</div>;

  if (!user) {
    router.push(ApiRoute.AUTHENTICATION_LOGIN);

    return <Loading size="large" />;
  }

  if (user && !user.permissions.includes(Auth0Permission.APPLICATION_ACCESS)) {
    router.push("/401");

    return <Loading size="large" />;
  }

  return (
    <Layout.Header
      className="flex justify-between"
      style={{
        border: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      <ThePiqueLabLogo />
      <div className="flex items-center gap-2">
        <Tooltip
          title={
            isBusinessOpen
              ? "Accepting Client Calls"
              : "Not Accepting Client Calls"
          }
        >
          <Tag
            className="cursor-default"
            id="businessStatus"
            color={
              isBusinessOpen ? "var(--semantic-green)" : "var(--semantic-red"
            }
          >
            {isBusinessOpen ? "Open" : "Closed"}
          </Tag>
        </Tooltip>
        <Tooltip title="Dial Pad">
          <Button
            type="text"
            icon={<Icon className={HEADER_ICON_CLASSES} component={DialPad} />}
            onClick={() => setShowDialer(!showDialer)}
          />
        </Tooltip>
        <Tooltip title="Settings">
          <Button
            type="text"
            icon={
              <SettingOutlinedIcon className="!text-2xl !text-tpl-navy" />
            }
            onClick={() => setShowSettings(!showSettings)}
          />
        </Tooltip>
        <Select
          className="user-availability-status-selector"
          disabled={
            !device ||
            updatingUserAvailabilityStatus ||
            deviceRegistering ||
            userAvailabilityStatus === UserAvailabilityStatus.OnACall ||
            userAvailabilityStatus === UserAvailabilityStatus.Ringing ||
            userAvailabilityStatus === UserAvailabilityStatus.WrappingUp
          }
          loading={updatingUserAvailabilityStatus || deviceRegistering}
          options={[
            {
              label: "Available",
              value: UserAvailabilityStatus.Available,
            },
            {
              label: "Break",
              value: UserAvailabilityStatus.Break,
            },
            {
              label: "Busy",
              value: UserAvailabilityStatus.Busy,
            },
            {
              label: "Offline",
              value: UserAvailabilityStatus.Offline,
            },
            {
              disabled: true,
              label: "Ringing",
              value: UserAvailabilityStatus.Ringing,
            },
            {
              disabled: true,
              label: "On A Call",
              value: UserAvailabilityStatus.OnACall,
            },
            {
              disabled: true,
              label: "Wrapping Up",
              value: UserAvailabilityStatus.WrappingUp,
            },
          ]}
          onChange={(value) => {
            setUserAvailabilityStatus({ status: value });
          }}
          style={{
            minWidth: "126px",
          }}
          value={userAvailabilityStatus}
        />
        <Dropdown
          menu={{ items: userAvatarMenuItems }}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <div>
            <UserAvatar
              status={userAvailabilityStatus}
              initials={userInitials ?? undefined}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </Dropdown>
        <Drawer
          closable={false}
          // mask={false}
          onClose={() => setShowDialer(false)}
          open={showDialer}
          styles={{
            body: {
              padding: 0,
            },
          }}
          title={
            <span className="flex w-full justify-between items-center">
              Dialer
              <Tooltip title="Close">
                <Button
                  icon={
                    <CloseOutlinedIcon className="!text-tpl-navy" />
                  }
                  onClick={async () => setShowDialer(false)}
                  shape="circle"
                  type="text"
                />
              </Tooltip>
            </span>
          }
        >
          <Dialer
            disabled={!device || deviceRegistering}
            loading={creatingCall}
            onDial={onDialerCall}
          />
        </Drawer>
      </div>
    </Layout.Header>
  );
}
